import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { ITrainingProgram } from '../models/ITrainingProgram';

function TPCardComponent({ trainingProgram, id }: any) {
  const trainingProgramTemp: ITrainingProgram = trainingProgram;
  const idTemp: string = id;
  return (
    // <Card style={{ width: '18rem' }}>
    <Card>
      <Card.Img variant="top" src={trainingProgramTemp.thumbnail} />
      <Card.Body>
        <Card.Title>{trainingProgramTemp.name}</Card.Title>
        <Card.Text>
          {trainingProgramTemp.description.length > 300
            ? `${trainingProgramTemp.description.slice(0, 300)} ...`
            : trainingProgramTemp.description}
        </Card.Text>
        <Button variant="pbo" as={Link as any} to={`/trainingProgram/${idTemp}`}>Ver</Button>
      </Card.Body>
    </Card>
  );
}

// TPCardComponent.propTypes = {
//   trainingProgram: PropTypes.shape({
//     id: PropTypes.string.isRequired,
//     thumbnail: PropTypes.string.isRequired,
//     name: PropTypes.string.isRequired,
//     description: PropTypes.string.isRequired,
//   }).isRequired,
// };

export default TPCardComponent;
