import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
// import { useNavigate } from 'react-router-dom';
import { auth } from '../configs/firebase-config';

function LoginComponent() {
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [showModalRegister, setShowModalRegister] = useState<boolean>(false);

  const closeRegisterModal = () => setShowModalRegister(false);

  // const navigate = useNavigate();

  const handleInputChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    switch (name) {
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
      case 'rememberMe':
        setRememberMe(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (email && password) {
      setPersistence(auth, rememberMe ? browserLocalPersistence : browserSessionPersistence)
        .then(async () => {
          try {
            await signInWithEmailAndPassword(auth, email, password);
            // navigate('/gallery');
          } catch (error: any) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(`${errorCode} ${errorMessage}`);
          }
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(`${errorCode} ${errorMessage}`);
        });
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>

        <Form.Group className="mb-3" controlId="formLoginEmail">
          <Form.Label>Correo electrónico</Form.Label>
          <Form.Control type="email" placeholder="Correo electrónico" name="email" value={email || ''} onChange={handleInputChange} />
          <Form.Text className="text-muted">
            No compartiremos el email con nadie
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formLoginPassword">
          <Form.Label>Contraseña</Form.Label>
          <Form.Control type="password" placeholder="Contraseña" name="password" value={password || ''} onChange={handleInputChange} />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formLoginCheckbox">
          <Form.Check type="checkbox" label="Recuérdame" name="rememberMe" checked={rememberMe} onChange={handleInputChange} />
        </Form.Group>

        <Button variant="pbo" type="submit">
          Iniciar Sesión
        </Button>

        <Button variant="secondary" className="mx-2" type="button" onClick={() => setShowModalRegister(true)}>
          Registrarse
        </Button>

      </Form>

      <Modal size="lg" centered show={showModalRegister} onHide={closeRegisterModal}>
        <Modal.Header closeButton>
          <Modal.Title>Atención</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Serás redirigido a la plataforma de PBO para completar el registro.</p>
          <p>En cuanto hayas completado el registro, vuelve para hacer login y completar el proceso de compra.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button href="https://factory.powerbuildingoficial.com/signin" variant="pbo">Llevame al registro</Button>
          <Button variant="secondary" onClick={closeRegisterModal}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LoginComponent;
