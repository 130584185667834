import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Gallery from './pages/Gallery';
import Documentary from './pages/Documentary';
import TrainingProgram from './pages/TrainingProgram';
import PromoCodeRedeem from './pages/PromoCodeRedeem';
import APPCheckout from './pages/APPCheckout';
import OrderCheckout from './pages/OrderCheckout';
import FactorySubscriptionCheckout from './pages/FactorySubscriptionCheckout';
import FactorySubscription from './pages/FactorySubscription';
import NavigationbarComponent from './components/NavigationbarComponent';
import PurchaseCheckout from './pages/PurchaseCheckout';

function App() {
  return (
    <BrowserRouter>
      <NavigationbarComponent />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/documentary/:documentaryId" element={<Documentary />} />
        <Route path="/trainingProgram/:trainingProgramId" element={<TrainingProgram />} />
        <Route path="/promoCodeRedeem/:promoCodeId" element={<PromoCodeRedeem />} />
        <Route path="/appCheckout/:productType/:productId" element={<APPCheckout />} />
        <Route path="/orderCheckout/:orderId" element={<OrderCheckout />} />
        <Route path="/factoryCheckout/:subscriptionId" element={<FactorySubscriptionCheckout />} />
        <Route path="/purchaseCheckout/:purchaseId" element={<PurchaseCheckout />} />
        <Route path="/factorySubscription" element={<FactorySubscription />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
