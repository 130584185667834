import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useStripe } from '@stripe/react-stripe-js';

type VoidCallback = () => void;
function StripeInfoModal({ onPaymentSucceeded, onSuccessText }: { onPaymentSucceeded?: VoidCallback, onSuccessText?: string }) {
  const stripe = useStripe();

  const [showStripeInfo, setShowStripeInfo] = useState(false);
  const [stripeInfoTitle, setStripeInfoTitle] = useState<string>();
  const [stripeInfoBody, setStripeInfoBody] = useState<string>();

  const closeStripeInfoModal = () => setShowStripeInfo(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (paymentIntent) {
        switch (paymentIntent.status) {
          case 'succeeded':
            setStripeInfoTitle('Pago completado');
            setStripeInfoBody(onSuccessText);
            if (onPaymentSucceeded) {
              onPaymentSucceeded();
            }
            break;
          case 'processing':
            setStripeInfoTitle('Procesando pago');
            setStripeInfoBody('Tu pago está siendo procesado.');
            break;
          case 'requires_payment_method':
            setStripeInfoTitle('Error');
            setStripeInfoBody('El pago no pudo completarse, por favor, inténtelo de nuevo.');
            break;
          case 'requires_action':
            setStripeInfoTitle('Se necesita verificación');
            setStripeInfoBody('Consulte su la entidad bancaria (web, app...) para la aprobación de la compra.');
            break;
          default:
            setStripeInfoTitle('Error');
            setStripeInfoBody('Ha ocurrido un error, por favor, inténtelo de nuevo.');
            break;
        }
        setShowStripeInfo(true);
      }
    });
    console.log('StripePaimentModal - UseEffect');
  }, [stripe]);

  return (
    <Modal size="lg" centered show={showStripeInfo} onHide={closeStripeInfoModal}>
      <Modal.Header closeButton>
        <Modal.Title>{stripeInfoTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {stripeInfoBody}
      </Modal.Body>
    </Modal>
  );
}

StripeInfoModal.defaultProps = {
  onPaymentSucceeded: () => {},
  onSuccessText: 'El pago ha sido realizado correctamente y esta disponible en tu aplicación. Muchas gracias',
};

export default StripeInfoModal;
