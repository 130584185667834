import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { httpsCallable } from 'firebase/functions';
import { useParams } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import { functions } from '../configs/firebase-config';
import GenericModal from '../components/GenericModal';

function PromoCodeRedeem() {
  const [showPromoCode, setShowPromoCode] = useState(false);
  const [modalPromoCodeErrorShow, setModalPromoCodeErrorShow] = useState(false);
  const [modalPromoCodeErrorMsg, setModalPromoCodeErrorMsg] = useState<string>();
  const [promoCode, setPromoCode] = useState<string>();
  const params = useParams();

  const closePromoCodeModal = () => setShowPromoCode(false);
  const showPromoCodeModal = () => setShowPromoCode(true);

  const handlePromoCodeRedeem = async (e: any) => {
    e.preventDefault();
    const redeemPromoCode = httpsCallable<any, any>(functions, 'socialFunctions-redeemPromoCode');
    try {
      const promoCodeId = params.promoCodeId;
      const promoCodeCode = await redeemPromoCode({ promoCodeId });
      showPromoCodeModal();
      setPromoCode(promoCodeCode.data.promoCodeCode);
    } catch (error: any) {
      setModalPromoCodeErrorShow(true);

      if (error.message === 'Promo code already used') {
        setModalPromoCodeErrorMsg(
          '¡Se te han adelantado! Ya tenemos ganador de la cesta Quamtrax, pero no han terminado las sorpresas, '
          + '¡Muchas gracias por formar parte de este REWIND FITNESS 2022!',
        );
      } else if (error.message === 'Promo code not active') {
        setModalPromoCodeErrorMsg('¡Vaya! Aún no hemos llegado a 500 espectadores… ¡Comparte el directo para conseguirlo y poder ganar la cesta!');
      }
    }
  };

  return (
    <>
      <Container>
        <Row>
          <Col sm={{ span: 8, offset: 2 }} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }} className="mt-sm-5">
            <div className="text-center">
              <h1 className="mt-sm-5">REWIND FITNESS HISPANO</h1>
              <Image
                src="https://firebasestorage.googleapis.com/v0/b/power-building-oficial.appspot.com/o/banners%2Fapp%2Fquamtrax500.jpg?alt=media&token=a2e51727-3a64-4371-a193-b4101a9b8449"
                fluid
                className="mb-2"
              />
              <Button variant="pbo" onClick={handlePromoCodeRedeem}>Obtener código</Button>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal size="lg" centered show={showPromoCode} onHide={closePromoCodeModal}>
        <Modal.Header closeButton>
          <Modal.Title>REWIND FITNESS HISPANO</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¡HAS SIDO EL PRIMERO!</p>
          <p>¡ACABAS DE GANAR 500€ EN SUPLEMENTACIÓN QUAMTRAX!🎉🎉</p>
          <p>{promoCode}</p>
          <p>Aquí tienes tu código, recuerda que es de un solo uso y tendrás 2023 para canjearlo en su web.</p>
          <p>¡Muchas gracias por formar parte de este REWIND FITNESS 2022!🥳🎉</p>
        </Modal.Body>
      </Modal>
      <GenericModal
        title="REWIND FITNESS HISPANO"
        body={modalPromoCodeErrorMsg}
        show={modalPromoCodeErrorShow}
        onHide={() => setModalPromoCodeErrorShow(false)}
      />
    </>
  );
}

export default PromoCodeRedeem;
