import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { httpsCallable } from 'firebase/functions';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { stripePromise } from '../configs/stripe-config';
import { IFactorySubscription } from '../models/IFactorySubscription';
import { functions } from '../configs/firebase-config';
import StripeCheckoutForm from '../components/StripeCheckoutForm';
import PBOLoadingImage from '../components/PBOLoadingImage';
import { setTheme, THEME } from '../configs/ThemeConfig';
import ModalError from '../components/ModalError';

function FactorySubscriptionCheckout() {
  const [searchParams] = useSearchParams();
  const params = useParams();

  const [stripeOptions, setStripeOptions] = useState<StripeElementsOptions>();
  const [subscriptionInfo, setSubscriptionInfo] = useState<IFactorySubscription>();
  const [stripeLoading, setStripeLoading] = useState(true);
  const [showModalError, setShowModalError] = useState<boolean>();

  if (searchParams.get('theme')) {
    setTheme(searchParams.get('theme') === 'dark' ? THEME.DARK : THEME.LIGHT);
  }

  async function loadStripe(subscription: IFactorySubscription) {
    try {
      console.log(subscription);
      const obtainedClientSecret = subscription.paymentSecret ?? undefined;
      const options = {
        clientSecret: obtainedClientSecret,
      };
      setStripeOptions(options);
    } catch (error) {
      console.log(error); // TODO: Mensaje de error
    }
  }

  async function loadSubscriptionInfo() {
    if (!params.subscriptionId) {
      return undefined;
    }

    try {
      const subscriptionResponse = await httpsCallable<any, any>(functions, 'socialFunctions-fetchSubscription')({
        subscriptionId: params.subscriptionId,
        uid: searchParams.get('uid'),
      });

      if (subscriptionResponse.data.res) {
        console.log(subscriptionResponse.data.subscription);
        const subscription = subscriptionResponse.data.subscription as IFactorySubscription;
        setSubscriptionInfo(subscription);
        return subscription;
      }

      console.error('Invalid order response', subscriptionResponse.data);
    } catch (error) {
      console.error('Failed to load subscription', params.subscriptionId);
      console.error(error); // TODO: Mensaje de error
    }

    return undefined;
  }

  useEffect(() => {
    async function asyncLoading() {
      // TODO: Puede cargarse de manera paralela
      const subscription = await loadSubscriptionInfo();
      if (subscription) {
        await loadStripe(subscription);
      } else {
        setShowModalError(true);
        console.error('Not loading stripe');
      }
      setStripeLoading(false);
    }
    asyncLoading();
  }, []);

  return (
    <>
      {stripeLoading
        && <PBOLoadingImage height={200} />}
      {!stripeLoading
        && stripeOptions
        && Object.keys(stripeOptions).length !== 0
        && Object.getPrototypeOf(stripeOptions) === Object.prototype
        ? (
          <Elements stripe={stripePromise} options={stripeOptions}>
            <StripeCheckoutForm factorySubscriptionInfo={subscriptionInfo} returnUrl={subscriptionInfo?.returnUrl} />
            <ModalError
              errorMsg="Ha ocurrido un problema al aplicar cargar el carrito"
              show={showModalError}
              onHide={() => setShowModalError(false)}
            />
          </Elements>
        )
        : null}
    </>
  );
}

export default FactorySubscriptionCheckout;
