import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';

function StripeSubscriptionModal() {
  const stripe = useStripe();
  const elements = useElements();

  const [showStripePayment, setShowStripePayment] = useState(true);
  const [message, setMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const closeStripeSubscriptionModal = () => setShowStripePayment(false);
  // const showStripeSubscriptionModal = () => setShowStripePayment(true);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: window.location.href,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message ?? 'Unknown error');
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  return (
    <Modal size="lg" centered show={showStripePayment} onHide={closeStripeSubscriptionModal}>
      <form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Subscripción</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PaymentElement id="payment-element" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeStripeSubscriptionModal}>
            Cancelar
          </Button>
          <Button variant="pbo" disabled={isLoading || !stripe || !elements} type="submit">
            {isLoading
              ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              : 'Comprar'}
          </Button>
          {message && <div id="payment-message">{message}</div>}
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default StripeSubscriptionModal;
