// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfigPro = {
  apiKey: 'AIzaSyAIisfjJpTdoAoVOjP5sTNkhIIW_ttJL_Y',
  authDomain: 'power-building-oficial.firebaseapp.com',
  databaseURL: 'https://power-building-oficial.firebaseio.com',
  projectId: 'power-building-oficial',
  storageBucket: 'power-building-oficial.appspot.com',
  messagingSenderId: '498966690314',
  appId: '1:498966690314:web:6135dd0dce3606de1dbdf3',
  measurementId: 'G-SSTCPMDWDY',
};

const firebaseConfigDev = {
  apiKey: 'AIzaSyAsMqIEIXbehrWrB8SyhbgbS8SvG6RBgPw',
  authDomain: 'power-building-oficial-dev.firebaseapp.com',
  projectId: 'power-building-oficial-dev',
  storageBucket: 'power-building-oficial-dev.appspot.com',
  messagingSenderId: '974405097823',
  appId: '1:974405097823:web:ee24a48a3ef987e7763eea',
  measurementId: 'G-6R9E1WF5SW',
};

export const firebaseConfig = (process.env.REACT_APP_PBO_DEV) ? firebaseConfigDev : firebaseConfigPro;

export default firebaseConfig;
