import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  where,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { Elements } from '@stripe/react-stripe-js';
import { httpsCallable } from 'firebase/functions';
import { onAuthStateChanged } from 'firebase/auth';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { auth, db, functions } from '../configs/firebase-config';
import { stripePromise } from '../configs/stripe-config';
import StripePaymentModal from '../components/StripePaymentModal';
import StripeInfoModal from '../components/StripeInfoModal';
import { ITrainingProgram } from '../models/ITrainingProgram';
import { setTheme, THEME } from '../configs/ThemeConfig';

function TrainingProgram() {
  const [trainingProgram, setTrainingProgram] = useState<ITrainingProgram>();
  const [stripeOptions, setStripeOptions] = useState<StripeElementsOptions>();
  const [stripeLoading, setStripeLoading] = useState(false);
  // const [loggedUser, setLoggedUser] = useState(null);
  const [showStripeInfoModal, setShowStripeInfoModal] = useState(false);
  const [buyerId, setBuyerId] = useState<string>();
  const [isInOwnership, setIsInOwnership] = useState(true);
  const [purchaseId, setPurchaseId] = useState<string>();

  const params = useParams();
  const [searchParams] = useSearchParams();

  if (searchParams.get('theme')) {
    setTheme(searchParams.get('theme') === 'dark' ? THEME.DARK : THEME.LIGHT);
  }

  async function loadStripe() {
    setStripeLoading(true);
    const createPaymentIntent = httpsCallable<any, any>(functions, 'stripeFunctions-createPaymentIntent');
    try {
      const clientSecretPromise = await createPaymentIntent({
        trainingProgramId: params.trainingProgramId,
        type: 'trainingProgram',
        userId: buyerId,
      });
      const obtainedClientSecret = clientSecretPromise.data.client_secret;
      setPurchaseId(clientSecretPromise.data.purchase_id);
      const options = {
        clientSecret: obtainedClientSecret,
      };
      setStripeOptions(options);
    } catch (error) {
      console.log(error);
    } finally {
      setStripeLoading(false);
    }
  }

  function showStripeMessages() {
    const intentClientSecret = searchParams.get('payment_intent_client_secret');
    if (showStripeInfoModal && intentClientSecret !== null) {
      return (
        <Elements stripe={stripePromise} options={{ clientSecret: intentClientSecret }}>
          {/* <StripeInfoModal data={{ documentaryId: params.documentaryId }} />  // TODO: Revisar parametros */}
          <StripeInfoModal />
        </Elements>
      );
    }
    return null;
  }

  function renderBuyButton() {
    if (stripeLoading) {
      return (
        <Button variant="pbo" disabled>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <span className="visually-hidden">Cargando...</span>
        </Button>
      );
    }
    if (stripeOptions
      && Object.keys(stripeOptions).length !== 0
      && Object.getPrototypeOf(stripeOptions) === Object.prototype) {
      return (
        <Elements stripe={stripePromise} options={stripeOptions}>
          {/* <StripePaymentModal data={{ trainingProgramId: params.trainingProgramId }} /> */}
          <StripePaymentModal productInfo={trainingProgram} purchaseId={purchaseId} />
        </Elements>
      );
    }
    return (
      <Button variant="pbo" className="mr-1" disabled={isInOwnership} onClick={() => loadStripe()}>
        Comprar
      </Button>
    );
  }

  useEffect(() => {
    const trainingProgramId = params.trainingProgramId;
    if (!trainingProgramId) {
      return;
    }
    const isIntentSecret = !!searchParams.get('payment_intent_client_secret');
    const isGiftTemp = !!searchParams.get('uid');
    const buyerIdTemp = searchParams.get('uid');

    setShowStripeInfoModal(isIntentSecret);
    // setIsGift(isGiftTemp);

    if (!isGiftTemp) {
      onAuthStateChanged(auth, (user) => {
        // setLoggedUser(user);
        if (user !== null) {
          setBuyerId(user.uid);
        }
      });
    } else if (buyerIdTemp !== null) {
      setBuyerId(buyerIdTemp);
    }

    async function userOwnsItem() {
      const uidToUse = isGiftTemp ? buyerIdTemp : buyerId;
      if (uidToUse) {
        const purchasesRef = collection(db, 'purchases');
        const purchasesQuery = query(
          purchasesRef,
          where('uId', '==', uidToUse),
          where('productId', '==', params.trainingProgramId),
          where('status', '==', 'paid'),
          limit(1),
        );

        const querySnapshot = await getDocs(purchasesQuery);
        setIsInOwnership(!querySnapshot.empty || isIntentSecret);
      }
    }

    async function getTrainingProgramInfo() {
      const trainingProgramRef = doc(db, 'trainingPrograms', trainingProgramId ?? '');
      const trainingProgramSnap = await getDoc(trainingProgramRef);
      const trainingProgramSnapData = trainingProgramSnap.data() as ITrainingProgram;
      setTrainingProgram(trainingProgramSnapData);
    }

    getTrainingProgramInfo();
    userOwnsItem();
  }, [buyerId]);

  return (
    trainingProgram ? (
      <Container>

        <Row className="position-relative bg-dark-transparent-gradient" style={{ overflow: 'hidden', minHeight: '50vh' }}>
          <video autoPlay loop muted poster={trainingProgram.thumbnail} className="bg-trailer">
            {trainingProgram.trailer
              ? <source src={trainingProgram.trailer} type="video/mp4" />
              : null}
          </video>
          <Col lg={6}>
            <h1>{trainingProgram.name}</h1>
            <p>{trainingProgram.description}</p>
            <p>
              <span className="fs-4">
                {trainingProgram.priceDiscount}
                €
              </span>
              <span className="ms-2 fs-5 text-decoration-line-through">
                {trainingProgram.price}
                €
              </span>
            </p>
            {renderBuyButton()}
          </Col>
        </Row>

        {showStripeMessages()}

      </Container>
    )
      : null
  );
}

export default TrainingProgram;
