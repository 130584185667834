import React from 'react';
import { useSearchParams } from 'react-router-dom';
import logo from '../assets/images/pbo-logo.png';

function PBOLoadingImage({ height }: any) {
  const [searchParams] = useSearchParams();

  const getLogo = () => {
    let ret = logo;

    if (searchParams.get('id')) {
      const userId = searchParams.get('id');
      ret = `https://firebasestorage.googleapis.com/v0/b/power-building-oficial.appspot.com/o/users%2F${userId}%2FlandingLoadingImage.png?alt=media`;
    }

    return ret;
  };

  return (
    <img
      src={getLogo()}
      // width="100vv"
      height={`${height}rem`}
      // className="d-inline-block align-top"
      style={{ display: 'block', margin: 'auto' }}
      className="scale-up-center"
      alt="Loading icon"
      onError={({ target }) => {
        const currentTarget : any = target;
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = logo;
      }}
    />
  );
}

export default PBOLoadingImage;
