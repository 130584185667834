import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { httpsCallable } from 'firebase/functions';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { stripePromise } from '../configs/stripe-config';
import { IOrder } from '../models/IOrder';
import { functions } from '../configs/firebase-config';
import StripeCheckoutForm from '../components/StripeCheckoutForm';
import PBOLoadingImage from '../components/PBOLoadingImage';
import { setTheme, THEME } from '../configs/ThemeConfig';
import ModalError from '../components/ModalError';

function OrderCheckout() {
  const [searchParams] = useSearchParams();
  const params = useParams();

  const [stripeOptions, setStripeOptions] = useState<StripeElementsOptions>();
  const [orderInfo, setOrderInfo] = useState<IOrder>();
  const [stripeLoading, setStripeLoading] = useState(true);
  const [purchaseId, setPurchaseId] = useState<string>();
  const [showModalError, setShowModalError] = useState<boolean>();

  if (searchParams.get('theme')) {
    setTheme(searchParams.get('theme') === 'dark' ? THEME.DARK : THEME.LIGHT);
  }

  async function loadStripe(order: IOrder) {
    try {
      console.log(order);
      const obtainedClientSecret = order.paymentSecret ?? undefined;
      setPurchaseId(order.paymentIntentId);
      const options = {
        clientSecret: obtainedClientSecret,
      };
      setStripeOptions(options);
    } catch (error) {
      console.log(error); // TODO: Mensaje de error
    }
  }

  async function loadOrderInfo() {
    if (!params.orderId) {
      return undefined; // TODO: Poner aqui mensaje de error
    }

    try {
      const orderResponse = await httpsCallable<any, any>(functions, 'socialFunctions-fetchOrder')({
        orderId: params.orderId,
        uid: searchParams.get('uid'),
      });

      if (orderResponse.data.res) {
        console.log(orderResponse.data.order);
        const order = orderResponse.data.order as IOrder;
        setOrderInfo(order);
        return order;
      }

      console.error('Invalid order response', orderResponse.data);
    } catch (error) {
      console.error('Failed to load order', params.orderId);
      console.error(error); // TODO: Mensaje de error
    }

    return undefined;
  }

  useEffect(() => {
    async function asyncLoading() {
      // TODO: Puede cargarse de manera paralela
      const order = await loadOrderInfo();
      if (order) {
        await loadStripe(order);
      } else {
        setShowModalError(true);
        console.error('Not loading stripe');
      }
      setStripeLoading(false);
    }
    asyncLoading();
  }, []);

  return (
    <>
      {stripeLoading
        && <PBOLoadingImage height={200} />}
      {!stripeLoading
        && stripeOptions
        && Object.keys(stripeOptions).length !== 0
        && Object.getPrototypeOf(stripeOptions) === Object.prototype
        ? (
          <Elements stripe={stripePromise} options={stripeOptions}>
            <StripeCheckoutForm orderInfo={orderInfo} purchaseId={purchaseId} returnUrl={orderInfo?.returnUrl} />
            <ModalError
              errorMsg="Ha ocurrido un problema al aplicar cargar el carrito"
              show={showModalError}
              onHide={() => setShowModalError(false)}
            />
          </Elements>
        )
        : null}
    </>
  );
}

export default OrderCheckout;
