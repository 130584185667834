import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoginComponent from '../components/LoginComponent';

function Login() {
  return (
    <Container>
      <Row>
        <Col sm={{ span: 8, offset: 2 }} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }} className="mt-sm-5">
          <h1 className="mt-sm-5">Iniciar Sesión</h1>
          <LoginComponent />
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
