import React from 'react';
import Modal from 'react-bootstrap/Modal';

function ModalError({ errorMsg, show, onHide }: any) {
  return (
    <Modal size="lg" centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{errorMsg}</p>
      </Modal.Body>
    </Modal>
  );
}

export default ModalError;
