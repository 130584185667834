import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useSearchParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../configs/firebase-config';
import ModalError from './ModalError';

function StripePaymentModal({ productInfo, purchaseId }: any) {
  const stripe = useStripe();
  const elements = useElements();
  const [searchParams] = useSearchParams();

  const [showStripePayment, setShowStripePayment] = useState(true);
  const [message, setMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState<any>({});
  const [promoCode, setPromoCode] = useState<string>();
  const [price, setPrice] = useState<number>(productInfo.priceDiscount > 0 ? productInfo.priceDiscount : productInfo.price);
  const [modalPromoCodeErrorShow, setModalPromoCodeErrorShow] = useState(false);
  const [promoCodeApplied, setPromoCodeApplied] = useState(false);

  const closeStripePaymentModal = () => setShowStripePayment(false);
  const showStripePaymentModal = () => setShowStripePayment(true);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: window.location.href,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message ?? 'Unknown error');
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  const handlePromoCodeChange = (e: any) => {
    setPromoCode(e.target.value);
  };

  const handlePromoCodeAccept = async (e: any) => {
    e.preventDefault();
    const applyPromoCode = httpsCallable<any, any>(functions, 'stripeFunctions-applyPromoCode');
    try {
      const newProductPrice = await applyPromoCode({
        purchaseId,
        promoCode,
      });
      setPrice(newProductPrice.data.newPrice / 100);
      setPromoCodeApplied(true);
    } catch (error) {
      setModalPromoCodeErrorShow(true);
    }
  };

  useEffect(() => {
    const uid = searchParams.get('uid');
    if (uid && uid !== null) {
      const getUserInfo = async function () {
        const userRef = doc(db, 'users', uid ?? ''); // TODO: Revisar
        const userSnap = await getDoc(userRef);
        const userSnapData = userSnap.data();
        setUserInfo(userSnapData);
      };
      getUserInfo();
    }
  }, []);

  return (
    <>
      <Button variant="pbo" onClick={showStripePaymentModal}>
        Comprar
      </Button>

      <Modal size="lg" centered show={showStripePayment} onHide={closeStripePaymentModal}>
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>{productInfo.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {searchParams.get('uid')
              && (
                <div className="d-flex align-items-center mb-3">
                  <div className="flex-shrink-0">
                    {userInfo.photo
                      && <Image src={userInfo.photo} alt="User Thumbnail" roundedCircle fluid style={{ height: '3.5rem' }} />}
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <p className="mb-0">Para:</p>
                    <p className="mb-0">{userInfo.nickname}</p>
                  </div>
                </div>
              )}
            <PaymentElement id="payment-element" />
            {/* <Form.Group className="my-3">
              <Form.Control type="text" placeholder="Código promocional" name="promoCode" value={promoCode || ''} onChange={handlePromoCodeChange} />
            </Form.Group> */}
            <InputGroup className="my-3">
              <Form.Control type="text" placeholder="Código promocional" name="promoCode" value={promoCode || ''} onChange={handlePromoCodeChange} disabled={promoCodeApplied} />
              <Button type="button" variant="pbo" id="button-addon2" onClick={handlePromoCodeAccept} disabled={promoCodeApplied}>
                Aplicar
              </Button>
            </InputGroup>
            <Form.Text className="text-muted" hidden={!promoCodeApplied}>
              ¡Descuento aplicado!
            </Form.Text>
          </Modal.Body>
          <Modal.Footer>
            <span className="fs-4">
              {price}
              €
            </span>
            <Button variant="secondary" onClick={closeStripePaymentModal}>
              Cancelar
            </Button>
            <Button variant="pbo" disabled={isLoading || !stripe || !elements} type="submit">
              {isLoading
                ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                : 'Comprar'}
            </Button>
            {message && <div id="payment-message">{message}</div>}
          </Modal.Footer>
        </form>
      </Modal>

      <ModalError
        errorMsg="Ha ocurrido un problema con el código de descuento"
        show={modalPromoCodeErrorShow}
        onHide={() => setModalPromoCodeErrorShow(false)}
      />
    </>
  );
}

export default StripePaymentModal;
