import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import { httpsCallable } from 'firebase/functions';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import ModalError from './ModalError';
import { functions } from '../configs/firebase-config';
import StripeInfoModal from './StripeInfoModal';
import GenericModal from './GenericModal';

function StripeCheckoutForm({
  productInfo,
  purchaseId,
  orderInfo,
  factorySubscriptionInfo,
  purchaseInfo,
  returnUrl,
}: any) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [promoCode, setPromoCode] = useState<string>();
  const [price, setPrice] = useState<number>();
  const [modalPromoCodeErrorShow, setModalPromoCodeErrorShow] = useState(false);
  const [modalPromoCodeFullDiscountShow, setModalPromoCodeFullDiscountShow] = useState(false);
  const [promoCodeApplied, setPromoCodeApplied] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl ?? window.location.href,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message ?? 'Unknown error');
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  const handlePromoCodeChange = (e: any) => {
    setPromoCode(e.target.value);
  };

  const handlePromoCodeAccept = async (e: any) => {
    e.preventDefault();
    const applyPromoCode = httpsCallable<any, any>(functions, 'stripeFunctions-applyPromoCode');
    try {
      const newProductPrice = await applyPromoCode({
        purchaseId,
        promoCode,
      });
      setPrice(newProductPrice.data.newPrice / 100);
      setPromoCodeApplied(true);
      setModalPromoCodeFullDiscountShow(true);
    } catch (error) {
      setModalPromoCodeErrorShow(true);
    }
  };

  useEffect(() => {
    if (productInfo) {
      setPrice(productInfo.priceDiscount > 0 ? productInfo.priceDiscount : productInfo.price);
    } else if (purchaseInfo) {
      setPrice(Math.ceil(purchaseInfo.productTotal) / 100);
    } else if (factorySubscriptionInfo) {
      setPrice(Math.ceil(factorySubscriptionInfo.totalPrice) / 100);
    } else {
      setPrice(Math.ceil(orderInfo.totalPrice + orderInfo.shippingCosts) / 100);
    }
  }, [productInfo]);

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h1>{productInfo ? productInfo.name : 'Carrito'}</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit} target="_self">
              <FormGroup>
                <PaymentElement id="payment-element" />
              </FormGroup>
              <InputGroup className="my-3" hidden={!productInfo}>
                <Form.Control type="text" placeholder="Código promocional" name="promoCode" value={promoCode || ''} onChange={handlePromoCodeChange} disabled={promoCodeApplied} />
                <Button type="button" variant="pbo" id="button-addon2" onClick={handlePromoCodeAccept} disabled={promoCodeApplied}>
                  Aplicar
                </Button>
              </InputGroup>
              <Form.Text className="text-muted" hidden={!promoCodeApplied}>
                ¡Descuento aplicado!
              </Form.Text>
              <p className="fs-4">
                {price !== 0
                  ? `${price} €`
                  : 'Gratis'}
              </p>
              <div className="d-grid gap-2">
                <Button variant="pbo" disabled={isLoading || !stripe || !elements} type="submit">
                  {isLoading
                    ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    : 'Comprar'}
                </Button>
              </div>

              {message && <div id="payment-message">{message}</div>}
            </Form>

          </Col>
        </Row>
      </Container>

      <ModalError
        errorMsg="Ha ocurrido un problema con el código de descuento"
        show={modalPromoCodeErrorShow}
        onHide={() => setModalPromoCodeErrorShow(false)}
      />

      <GenericModal
        title="Descuento aplicado"
        body="El descuento se ha aplicado y se ha añadido a su cuenta de forma gratuita."
        show={modalPromoCodeFullDiscountShow}
        onHide={() => setModalPromoCodeFullDiscountShow(false)}
      />

      {productInfo && <StripeInfoModal />}
      {!productInfo && <StripeInfoModal onSuccessText="El pago se ha completado correctamente. Puedes volver a la pestaña del carrito." />}
    </>
  );
}

export default StripeCheckoutForm;
