import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function GenericModal({
  title, body, show, onHide, buttonText, onButtonPressed,
}: any) {
  return (
    <Modal size="lg" centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ whiteSpace: 'pre-line' }}>{body}</p>
      </Modal.Body>
      {buttonText && (
        <Modal.Footer>
          <Button variant="primary" onClick={onButtonPressed}>
            {buttonText}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default GenericModal;
