import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { IDocumentary } from '../models/IDocumentary';
// import PropTypes from 'prop-types';

function DocumentaryCardComponent({ documental, id }: any) {
  const documentalTemp: IDocumentary = documental;
  const idTemp: string = id;
  return (
    // <Card style={{ width: '18rem' }}>
    <Card>
      <Card.Img variant="top" src={documentalTemp.mainImage} />
      <Card.Body>
        <Card.Title>{documentalTemp.name}</Card.Title>
        <Card.Text>
          {documentalTemp.synopsis.length > 300
            ? `${documentalTemp.synopsis.slice(0, 300)} ...`
            : documentalTemp.synopsis}
        </Card.Text>
        <Button variant="pbo" as={Link as any} to={`/documentary/${idTemp}`}>Ver</Button>
      </Card.Body>
    </Card>
  );
}

// DocumentaryCardComponent.propTypes = {
//   documental: PropTypes.shape({
//     id: PropTypes.string.isRequired,
//     mainImage: PropTypes.string.isRequired,
//     name: PropTypes.string.isRequired,
//     synopsis: PropTypes.string.isRequired,
//   }).isRequired,
// };

export default DocumentaryCardComponent;
