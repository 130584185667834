import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  where,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Player from '@vimeo/player';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Stack from 'react-bootstrap/Stack';
import { Elements } from '@stripe/react-stripe-js';
import { httpsCallable } from 'firebase/functions';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db, functions } from '../configs/firebase-config';
import { stripePromise } from '../configs/stripe-config';
import StripePaymentModal from '../components/StripePaymentModal';
import StripeInfoModal from '../components/StripeInfoModal';
import { IDocumentary } from '../models/IDocumentary';

function Documentary() {
  const [documentary, setDocumentary] = useState<IDocumentary>();
  const [showVimeoVideo, setShowVimeoVideo] = useState(false);
  const [videoLoading, setVideoLoading] = useState(false);
  const [documentaryPrivateData, setDocumentaryPrivateData] = useState<any>({});
  const [stripeOptions, setStripeOptions] = useState({});
  const [stripeLoading, setStripeLoading] = useState(false);
  // const [loggedUser, setLoggedUser] = useState(null);
  const [showStripeInfoModal, setShowStripeInfoModal] = useState(false);
  // const [isGift, setIsGift] = useState(false);
  const [buyerId, setBuyerId] = useState('');
  const [isInOwnership, setIsInOwnership] = useState(true);
  const [purchaseId, setPurchaseId] = useState<string>();

  const params = useParams();
  const [searchParams] = useSearchParams();

  function showViemoVideoModal() {
    setVideoLoading(true);
    const player = new Player('documental', {
      id: documentaryPrivateData.vimeoId,
      responsive: false,
      autoplay: true,
      transparent: false,
    });
    player.setVolume(0);
    player.ready().then(() => {
      setVideoLoading(false);
    });
  }

  async function loadStripe() {
    setStripeLoading(true);
    const createPaymentIntent = httpsCallable<any, any>(functions, 'stripeFunctions-createPaymentIntent');
    try {
      const clientSecretPromise = await createPaymentIntent({
        documentaryId: params.documentaryId,
        type: 'documentary',
        userId: buyerId,
      });
      const obtainedClientSecret = clientSecretPromise.data.client_secret;
      setPurchaseId(clientSecretPromise.data.purchase_id);
      const options = {
        clientSecret: obtainedClientSecret,
      };
      setStripeOptions(options);
    } catch (error) {
      console.log(error);
    } finally {
      setStripeLoading(false);
    }
  }

  function renderBuyOrPlayButton() {
    if (documentaryPrivateData
      && Object.keys(documentaryPrivateData).length !== 0
      && Object.getPrototypeOf(documentaryPrivateData) === Object.prototype) {
      return (
        <Button variant="pbo" onClick={() => setShowVimeoVideo(true)}>
          Ver
        </Button>
      );
    }
    if (stripeLoading) {
      return (
        <Button variant="pbo" disabled>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <span className="visually-hidden">Cargando...</span>
        </Button>
      );
    }
    if (stripeOptions
      && Object.keys(stripeOptions).length !== 0
      && Object.getPrototypeOf(stripeOptions) === Object.prototype) {
      return (
        <Elements stripe={stripePromise} options={stripeOptions}>
          {/* <StripePaymentModal data={{ documentaryId: params.documentaryId }} /> // TODO: Revisar parametros */}
          <StripePaymentModal productInfo={documentary} purchaseId={purchaseId} />
        </Elements>
      );
    }
    return (
      <Button variant="pbo" onClick={() => loadStripe()} disabled={isInOwnership}>
        Comprar
      </Button>
    );
  }

  function showStripeMessages() {
    if (showStripeInfoModal && searchParams.get('payment_intent_client_secret') !== null) {
      return (
        <Elements stripe={stripePromise} options={{ clientSecret: searchParams.get('payment_intent_client_secret') as string }}>
          {/* <StripeInfoModal data={{ documentaryId: params.documentaryId }} /> */}
          <StripeInfoModal />
        </Elements>
      );
    }
    return null;
  }

  useEffect(() => {
    if (!params.documentaryId) {
      return;
    }
    const isIntentSecret = !!searchParams.get('payment_intent_client_secret');
    const isGiftTemp = !!searchParams.get('uid');
    const buyerIdTemp = searchParams.get('uid');

    setShowStripeInfoModal(isIntentSecret);
    // setIsGift(isGiftTemp);

    if (!isGiftTemp) {
      onAuthStateChanged(auth, (user) => {
        // setLoggedUser(user);
        if (user !== null) {
          setBuyerId(user.uid);
        }
      });
    } else if (buyerIdTemp !== null) {
      setBuyerId(buyerIdTemp);
    }

    async function userOwnsItem() {
      const uidToUse = isGiftTemp ? buyerIdTemp : buyerId;
      if (uidToUse) {
        const purchasesRef = collection(db, 'purchases');
        const purchasesQuery = query(
          purchasesRef,
          where('uId', '==', uidToUse),
          where('productId', '==', params.documentaryId),
          where('status', '==', 'paid'),
          limit(1),
        );

        const querySnapshot = await getDocs(purchasesQuery);
        setIsInOwnership(!querySnapshot.empty || isIntentSecret);
      }
    }

    async function getDocumentaryPrivateData(id: string) {
      const documentalRef = doc(db, 'documentary', id);
      const documentalSnap = await getDoc(documentalRef);
      const documentalSnapData = documentalSnap.data();
      setDocumentaryPrivateData(documentalSnapData);
    }

    async function getDocumentaryInfo() {
      const documentalRef = doc(db, 'documentary', params.documentaryId ?? '');
      const documentalSnap = await getDoc(documentalRef);
      const documentalSnapData = documentalSnap.data() as IDocumentary;
      setDocumentary(documentalSnapData);
      getDocumentaryPrivateData(documentalSnapData.chapters[0].id);
    }

    getDocumentaryInfo();
    userOwnsItem();
  }, [buyerId]);

  return (
    documentary ? (
      <Container>

        <Row className="position-relative bg-dark-transparent-gradient" style={{ overflow: 'hidden', minHeight: '50vh' }}>
          <video autoPlay loop muted poster={documentary.mainImage} className="bg-trailer">
            {documentary.trailerUrl
              ? <source src={documentary.trailerUrl} type="video/mp4" />
              : null}
          </video>
          <Col lg={6}>
            <h1>{documentary.name}</h1>
            <p>{documentary.synopsis}</p>
            <p>
              {documentary.priceDiscount > 0
                ? (
                  <span className="fs-4">
                    {documentary.priceDiscount}
                    €
                  </span>
                )
                : <span className="fs-4">Gratis</span>}
              {documentary.price > 0
                ? (
                  <span className="ms-2 fs-5 text-decoration-line-through">
                    {documentary.price}
                    €
                  </span>
                )
                : null}
            </p>
            <Stack direction="horizontal" gap={2}>
              {renderBuyOrPlayButton()}
              <Button variant="pbo" disabled>Ver Tráiler</Button>
            </Stack>
          </Col>
        </Row>

        <Modal showVimeoVideo={showVimeoVideo} fullscreen onEntered={() => showViemoVideoModal()}>
          <Modal.Body className="p-0 overflow-hidden">
            <Button variant="outline-light" className="position-absolute" style={{ zIndex: '2', right: '0' }} onClick={() => setShowVimeoVideo(false)}>
              Cerrar
            </Button>
            {videoLoading
              ? <Spinner animation="border" role="status" variant="light" className="position-absolute top-50 start-50" style={{ zIndex: '1' }} />
              : null}
            <div id="documental" style={{ zIndex: '2' }} />
          </Modal.Body>
        </Modal>
        {showStripeMessages()}
      </Container>
    )
      : null
  );
}

export default Documentary;
