import React, { useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { BsFillMoonStarsFill } from 'react-icons/bs';
import { onAuthStateChanged, signOut, User } from 'firebase/auth';
import { auth } from '../configs/firebase-config';
import logo from '../assets/images/pbo-logo.png';
import { resetTheme, setTheme, THEME } from '../configs/ThemeConfig';

function NavigationbarComponent() {
  const [loggedUser, setLoggedUser] = useState<User | null>(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const signOutPressed = () => {
    signOut(auth).then(() => {
      navigate('/gallery');
    }).catch((error) => {
      console.log(error);
    });
  };

  useEffect(() => {
    // Set custom favicon if needed
    if (searchParams.get('id')) {
      const userId = searchParams.get('id');
      const favicon = `https://firebasestorage.googleapis.com/v0/b/power-building-oficial.appspot.com/o/users%2F${userId}%2FlandingFaviconImage.png?alt=media`;

      // Set the favicon URL
      let link = document.querySelector<HTMLLinkElement>("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link') as HTMLLinkElement;
        link.rel = 'icon';
        document.head.appendChild(link);
      }
      link!.href = favicon;
    }

    onAuthStateChanged(auth, (user) => {
      setLoggedUser(user);
    });
  }, [searchParams]);

  const getLogo = () => {
    let ret = logo;

    if (searchParams.get('id')) {
      const userId = searchParams.get('id');
      ret = `https://firebasestorage.googleapis.com/v0/b/power-building-oficial.appspot.com/o/users%2F${userId}%2FlandingHeaderImage.png?alt=media`;
    }

    return ret;
  };

  return searchParams.get('noNavigation') === ''
    ? (
      <Navbar collapseOnSelect expand="lg">
        <Container>
          <Navbar.Brand>
            <img
              src={getLogo()}
              // width="30"
              height="60"
              className="d-inline-block align-top"
              alt="Logo"
              onError={({ target }) => {
                const currentTarget : any = target;
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = logo;
              }}
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
    )
    : (
      <Navbar collapseOnSelect expand="lg">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img
              src={getLogo()}
              // width="30"
              height="60"
              className="d-inline-block align-top"
              alt="Logo"
              onError={({ target }) => {
                const currentTarget : any = target;
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = logo;
              }}
            />
          </Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/">Inicio</Nav.Link>
              <Nav.Link as={Link} to="/gallery?type=documentary">Documentales</Nav.Link>
              <Nav.Link as={Link} to="/gallery?type=trainingPrograms">Programas</Nav.Link>
            </Nav>
            {loggedUser
              ? <Button variant="pbo" onClick={signOutPressed} type="button">Cerrar sesión</Button>
              : <Button variant="pbo" as={Link as any} to="/login">Iniciar Sesión</Button>}
          </Navbar.Collapse>
          <NavDropdown title={<BsFillMoonStarsFill />} id="nav-theme-color" className="ms-2">
            <NavDropdown.Item onClick={() => setTheme(THEME.LIGHT)}>Light</NavDropdown.Item>
            <NavDropdown.Item onClick={() => setTheme(THEME.DARK)}>Dark</NavDropdown.Item>
            <NavDropdown.Item onClick={() => resetTheme()}>Auto</NavDropdown.Item>
          </NavDropdown>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </Container>
      </Navbar>
    );
}

export default NavigationbarComponent;
