import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  collection,
  getDocs,
} from 'firebase/firestore';
import { db } from '../configs/firebase-config';
import DocumentaryCardComponent from '../components/DocumentaryCardComponent';
import TPCardComponent from '../components/TPCardComponent';

function Gallery() {
  const [documentaries, setDocumentaries] = useState<any[]>();
  const [trainingPrograms, setTrainingPrograms] = useState<any[]>();
  const [searchParams] = useSearchParams();

  async function getItems(type: string) {
    const itemsQuery = collection(db, type);
    const tempRawitems = await getDocs(itemsQuery);
    const tempItems: any[] = tempRawitems.docs.map((doc) => (
      { ...doc.data(), id: doc.id }
    ));
    const itemsProcesados: any[] = [];
    for (let i = 0; i < Math.ceil(tempItems.length / 4); i += 1) {
      itemsProcesados.push(
        tempItems.slice(((1 + (4 * i)) - 1), ((4 * (i + 1)) - 1)),
      );
    }
    return (itemsProcesados);
  }

  function container(type: string) {
    const items = type === 'documentary' ? documentaries : trainingPrograms;
    if (!items || items.length === 0) {
      return <div />; // TODO: Revisar
    }

    return (
      <Container>
        {items.map((packItem) => (
          <Row key={`row${packItem[0].id}`}>
            {
              packItem.map((item: any) => (
                <Col lg={4} key={item.id} className="gy-4">
                  {type === 'documentary'
                    ? <DocumentaryCardComponent documental={item} id={item.id} />
                    : <TPCardComponent trainingProgram={item} id={item.id} />}
                </Col>
              ))
            }
          </Row>
        ))}
      </Container>
    );
  }

  useEffect(() => {
    async function fetchData() {
      if (searchParams.get('type') === 'documentary') { // Antes habia && documentaries.length === 0 no se por que
        setDocumentaries(await getItems('documentary'));
      } else if (searchParams.get('type') === 'trainingPrograms') { // Antes habia && trainingPrograms.length === 0 no se por que
        setTrainingPrograms(await getItems('trainingPrograms'));
      }
    }
    fetchData();
  }, [searchParams]);

  return (
    container(searchParams.get('type') ?? 'documentary')
  );
}

export default Gallery;
