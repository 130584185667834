import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { stripePromise } from '../configs/stripe-config';
import { ITrainingProgram } from '../models/ITrainingProgram';
import { IDocumentary } from '../models/IDocumentary';
import { db, functions } from '../configs/firebase-config';
import StripeCheckoutForm from '../components/StripeCheckoutForm';
import PBOLoadingImage from '../components/PBOLoadingImage';
import { setTheme, THEME } from '../configs/ThemeConfig';

function APPCheckout() {
  const [searchParams] = useSearchParams();
  const params = useParams();

  const [stripeOptions, setStripeOptions] = useState<StripeElementsOptions>();
  const [productInfo, setProductInfo] = useState<ITrainingProgram | IDocumentary>();
  const [stripeLoading, setStripeLoading] = useState(true);
  const [purchaseId, setPurchaseId] = useState<string>();

  if (searchParams.get('theme')) {
    setTheme(searchParams.get('theme') === 'dark' ? THEME.DARK : THEME.LIGHT);
  }

  async function loadStripe() {
    const createPaymentIntent = httpsCallable<any, any>(functions, 'stripeFunctions-createPaymentIntent');
    try {
      let requestObj;

      switch (params.productType) {
        case 'trainingProgram':
          requestObj = {
            trainingProgramId: params.productId,
            type: 'trainingProgram',
            userId: searchParams.get('uid'),
          };
          break;
        case 'documentary':
          requestObj = {
            documentaryId: params.productId,
            type: 'documentary',
            userId: searchParams.get('uid'),
          };
          break;
        default:
          return;
      }

      const clientSecretPromise = await createPaymentIntent(requestObj);
      const obtainedClientSecret = clientSecretPromise.data.client_secret;
      setPurchaseId(clientSecretPromise.data.purchase_id);
      const options = {
        clientSecret: obtainedClientSecret,
      };
      setStripeOptions(options);
    } catch (error) {
      console.log(error); // TODO: Mensaje de error
    }
  }

  async function loadProductInfo() {
    if (!params.productId) {
      return undefined; // TODO: Poner aqui mensaje de error
    }

    if (!params.productType) {
      return undefined; // TODO: Poner aqui mensaje de error
    }

    let collectionName: string;
    switch (params.productType) {
      case 'trainingProgram':
        collectionName = 'trainingPrograms';
        break;
      case 'documentary':
        collectionName = 'documentary';
        break;
      default:
        return undefined;
    }

    const productRef = doc(db, collectionName, params.productId);
    const productSpap = await getDoc(productRef);

    if (productSpap.exists()) {
      switch (collectionName) {
        case 'documentary':
          setProductInfo(productSpap.data() as IDocumentary);
          break;
        case 'trainingPrograms':
          setProductInfo(productSpap.data() as ITrainingProgram);
          break;
        default:
          return undefined;
      }
      return productSpap.data();
    }

    console.log('Error'); // TODO: Mensaje de error
    return undefined;
  }

  useEffect(() => {
    async function asyncLoading() {
      // TODO: Puede cargarse de manera paralela
      await loadProductInfo();
      await loadStripe();
      setStripeLoading(false);
    }
    asyncLoading();
  }, []);

  return (
    <>
      {stripeLoading
        && <PBOLoadingImage height={200} />}
      {!stripeLoading
        && stripeOptions
        && Object.keys(stripeOptions).length !== 0
        && Object.getPrototypeOf(stripeOptions) === Object.prototype
        ? (
          <Elements stripe={stripePromise} options={stripeOptions}>
            <StripeCheckoutForm productInfo={productInfo} purchaseId={purchaseId} />
          </Elements>
        )
        : null}
    </>
  );
}

export default APPCheckout;
